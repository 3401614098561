

import { useRestClient } from "./useRestClient"

export const useChatbot = (toast) => {

    const restClient = useRestClient()
    const createChatbot = async (params) => {
        return restClient.post('/api/v1/chatbots', {...params})
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })
    }

    const getChatbots = async (params) => {
        return restClient.get('/api/v1/chatbots', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })
    }

    const getChatbot = async (chatbotCode) => {
        return restClient.get('/api/v1/chatbots/'+chatbotCode)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })
    }

    const deleteChatbot = async (chatbotCode) => {
        return restClient.del('/api/v1/chatbots/'+chatbotCode)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })
    }

    const updateChatbot = async (chatbotCode, params) => {
        return restClient.patch('/api/v1/chatbots/'+chatbotCode, params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })
    }

    const startFlow = async (chatbotCode, params) => {
        return restClient.post('/api/v1/chatbots/'+chatbotCode+'/fire', {...params})
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })
    }

    const restartFlow = async (chatbotCode, params) => {
        return restClient.patch('/api/v1/chatbots/'+chatbotCode+'/fire', {...params})
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })
    }

    const clearSource = async (chatbotCode, params) => {
        return restClient.del('/api/v1/chatbots/'+chatbotCode+'/fire', {...params})
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })
    }

    const refreshTasks = async (chatbotCode, params) => {
        return restClient.put('/api/v1/chatbots/'+chatbotCode+'/fire', {...params})
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })
    }

    const getFlowStats = async (chatbotCode, params) => {
        return restClient.get('/api/v1/chatbots/'+chatbotCode+'/fire', {...params})
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })
    }

    const syncData = async (chatbotCode, params) => {
        return restClient.post('/api/v1/chatbots/'+chatbotCode+'/sync', {...params})
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })
    }

    const quickBot = async (chatbotCode, params) => {
        return restClient.post('/api/v1/chatbots/'+chatbotCode+'/quick_bot', {...params})
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })
    }

    return {
        createChatbot,
        getChatbot,
        getChatbots,
        updateChatbot,
        deleteChatbot,
        startFlow,
        restartFlow,
        clearSource,
        refreshTasks,
        getFlowStats,
        syncData,
        quickBot
    }
}